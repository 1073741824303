@import 'assets/scss/custom-theme.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/flag-icons/css/flag-icons.min.css';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 22px;
}
.flex{
  max-width: 100% !important;
}
h1{
  font-size: 2em;
  font-weight: bold;
  line-height: 1.8em;
}

h2{
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.4em;
}
h3{
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.1em;
}

.white-bg {
  background-color: white;
}

.text-center {
  text-align: center;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

p {
  margin: 10px;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}

a {
  color: #ee7711;
  font-size: 14px;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

.width-100 {
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.radio-image * .mat-radio-container{
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .mat-step-label {
    white-space: normal !important;
    overflow: visible !important;
  }
}
/*cookie consent*/
.light.cc_dialog button.cc_b_ok{
  background-color:#ee7711 !important;
}

.light.cc_dialog{
  max-width: 30% !important;
  padding: 20px 40px !important;
  font-family: Roboto !important;
  border-start-start-radius: 4px !important;
  box-shadow: #111 2px 4px 10px 1px !important;
  background-color: #f9f9f9 !important;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active{
//     -webkit-box-shadow: 0 0 0 30px white inset !important;
// }
